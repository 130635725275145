import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49')
];

export const server_loads = [0,3,4,5,7,8,9,11,10];

export const dictionary = {
		"/": [15],
		"/(authenticated)/admin": [20,[3,4]],
		"/(authenticated)/admin/templates": [~21,[3,4]],
		"/(authenticated)/admin/templates/[templateID]": [22,[3,4]],
		"/(authenticated)/admin/templates/[templateID]/[templateVersion]": [23,[3,4,5]],
		"/(authenticated)/admin/templates/[templateID]/[templateVersion]/[sectionID]": [~24,[3,4,5,6]],
		"/(authenticated)/admin/users": [25,[3,4]],
		"/(authenticated)/admin/users/[userID]": [26,[3,4,7]],
		"/(authenticated)/admin/users/[userID]/[profileID]": [27,[3,4,7,8]],
		"/(authenticated)/applications": [28,[3]],
		"/(authenticated)/applications/[proposalID]": [~29,[3,9]],
		"/(authenticate-account)/challenge": [16,[2]],
		"/coming-soon": [46],
		"/(authenticate-account)/confirmation": [17,[2]],
		"/contact-us": [47],
		"/(authenticated)/grant-projects": [30,[3]],
		"/(authenticated)/grant-projects/[projectID]": [~31,[3]],
		"/(authenticated)/grants-in-progress": [35,[3,11]],
		"/(authenticated)/grants-in-progress/[profileName]/[proposalID]/export-grant": [39,[3,11,12]],
		"/(authenticated)/grants-in-progress/[profileName]/[proposalID]/(write-grant)/grant-details": [36,[3,11,12,13]],
		"/(authenticated)/grants-in-progress/[profileName]/[proposalID]/grant-info": [40,[3,11,12]],
		"/(authenticated)/grants-in-progress/[profileName]/[proposalID]/grant-info/key-personnel": [41,[3,11,12]],
		"/(authenticated)/grants-in-progress/[profileName]/[proposalID]/review-grant": [42,[3,11,12]],
		"/(authenticated)/grants-in-progress/[profileName]/[proposalID]/(write-grant)/write-grant": [37,[3,11,12,13]],
		"/(authenticated)/grants-in-progress/[profileName]/[proposalID]/(write-grant)/write-grant/[sectionName]": [38,[3,11,12,13]],
		"/(authenticated)/grants": [32,[3,10]],
		"/(authenticated)/grants/[grantId=grantName]": [33,[3,10]],
		"/(authenticated)/grants/[grantId=grantName]/write": [34,[3,10]],
		"/(authenticated)/help": [43,[3]],
		"/(authenticate-account)/login": [18,[2]],
		"/(authenticated)/onboarding": [44,[14]],
		"/privacy-policy": [48],
		"/(authenticated)/settings": [45,[3]],
		"/(authenticate-account)/signup": [19,[2]],
		"/terms": [49]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';